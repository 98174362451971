import { render, staticRenderFns } from "./Campaigns.vue?vue&type=template&id=518d54ca&scoped=true"
import script from "./Campaigns.vue?vue&type=script&lang=js"
export * from "./Campaigns.vue?vue&type=script&lang=js"
import style0 from "./Campaigns.vue?vue&type=style&index=0&id=518d54ca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518d54ca",
  null
  
)

export default component.exports