var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('PageHeader',{attrs:{"title":"Escolha o tipo de campanha"}}),_c('section',{staticClass:"page-content container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_vm._v(" SMS ")]),_c('div',{staticClass:"card-body block-el"},[_c('i',{staticClass:"fas fa-sms"}),_c('router-link',{staticClass:"btn btn-success",attrs:{"type":"button","to":"/sms/campaigns"}},[_vm._v(" Visualize e crie campanhas SMS ")])],1)])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-body block-el"},[_c('i',{staticClass:"far fa-envelope"}),_c('router-link',{staticClass:"btn btn-success",attrs:{"type":"button","to":"/mail/campaigns"}},[_vm._v(" Visualize e crie campanhas SMS ")])],1)])]),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card soon"},[_c('div',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.$t('generic-str.voice'))+" ")]),_vm._m(5)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2"},[_c('img',{staticClass:"mascot",attrs:{"src":require("../assets/mascote-2.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_vm._v(" E-mail "),_c('span',{staticClass:"badge badge-success"},[_vm._v("beta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card soon"},[_c('div',{staticClass:"card-header"},[_vm._v(" Whatsapp ")]),_c('div',{staticClass:"card-body block-el"},[_c('i',{staticClass:"fab fa-whatsapp"}),_c('span',{staticClass:"soon-text"},[_vm._v("Em breve")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card soon"},[_c('div',{staticClass:"card-header"},[_vm._v(" RCS ")]),_c('div',{staticClass:"card-body block-el"},[_c('i',{staticClass:"far fa-comment-alt"}),_c('span',{staticClass:"soon-text"},[_vm._v("Em breve")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card soon"},[_c('div',{staticClass:"card-header"},[_vm._v(" Telegram ")]),_c('div',{staticClass:"card-body block-el"},[_c('i',{staticClass:"fab fa-telegram-plane"}),_c('span',{staticClass:"soon-text"},[_vm._v("Em breve")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body block-el"},[_c('i',{staticClass:"fas fa-microphone-alt"}),_c('span',{staticClass:"soon-text"},[_vm._v("Em breve")])])
}]

export { render, staticRenderFns }